import { EndpointsType } from "../../../src/types/Endpoints.types";
import { Meta } from "./SiteInfo";

export const BlogCategoriesEndpoint = "common_blog_cat";

export const NewsEndpointsForMenu = [
  "news_common", "news_sasaguri", "news_noogata", "news_hofu", "news_shimonoseki", "news_nobeoka"
]
export const BlogEndpointsForMenu = [
  "blog_sasaguri", "blog_noogata", "blog_hofu", "blog_shimonoseki", "blog_nobeoka"
]
export const VoiceEndpointsForMenu = [
  "voice_sasaguri", "voice_noogata", "voice_hofu", "voice_shimonoseki", "voice_nobeoka"
]

export const Endpoints: EndpointsType = {
  "news_common": {
    id: "news_common",
    name: "自在な整骨院からのお知らせ",
    categories: {},
    postsPerPage: 10,
    label: '全院',
    archivePath: '/news/',
    description: Meta.siteName + "のお知らせ"
  },

  // 防府院
  "news_hofu": {
    id: "news_hofu",
    name: "防府院からのお知らせ",
    categories: {},
    postsPerPage: 10,
    label: '防府',
    archivePath: '/shop/hofu/news/',
    description: Meta.siteName + " | 防府院からのお知らせ"    
  },
  "blog_hofu": {
    id: "blog_hofu",
    name: "防府院ブログ",
    categories: {},
    postsPerPage: 12,
    label: '防府',
    archivePath: '/shop/hofu/blog/',
    description: Meta.siteName + " | 防府院のブログ"
  },
  "voice_hofu": {
    id: "voice_hofu",
    name: "防府院のお客様の声",
    categories: {},
    postsPerPage: 12,
    label: '防府',
    archivePath: '/shop/hofu/voice/',
    description: Meta.siteName + " | 防府院のお客様の声"
  },

  // 延岡院
  "news_nobeoka": {
    id: "news_nobeoka",
    name: "延岡院からのお知らせ",
    postsPerPage: 10,
    label: '延岡',
    archivePath: '/shop/nobeoka/news/',
    description: Meta.siteName + " | 延岡院からのお知らせ"    
  },
  "blog_nobeoka": {
    id: "blog_nobeoka",
    name: "延岡院ブログ",
    postsPerPage: 12,
    label: '延岡',
    archivePath: '/shop/nobeoka/blog/',
    description: Meta.siteName + " | 延岡院のブログ"
  },
  "voice_nobeoka": {
    id: "voice_nobeoka",
    name: "延岡院のお客様の声",
    postsPerPage: 12,
    label: '延岡',
    archivePath: '/shop/nobeoka/voice/',
    description: Meta.siteName + " | 延岡院のお客様の声"
  },

  // 下関院
  "news_shimonoseki": {
    id: "news_shimonoseki",
    name: "下関院からのお知らせ",
    categories: {},
    postsPerPage: 10,
    label: '下関',
    archivePath: '/shop/shimonoseki/news/',
    description: Meta.siteName + " | 下関院からのお知らせ"    
  },
  "blog_shimonoseki": {
    id: "blog_shimonoseki",
    name: "下関院ブログ",
    categories: {},
    postsPerPage: 12,
    label: '下関',
    archivePath: '/shop/shimonoseki/blog/',
    description: Meta.siteName + " | 下関院のブログ"
  },
  "voice_shimonoseki": {
    id: "voice_shimonoseki",
    name: "下関院のお客様の声",
    categories: {},
    postsPerPage: 12,
    label: '下関',
    archivePath: '/shop/shimonoseki/voice/',
    description: Meta.siteName + " | 下関院のお客様の声"
  },

  // 篠栗院
  "news_sasaguri": {
    id: "news_sasaguri",
    // id: "news_hofu",
    name: "篠栗院からのお知らせ",
    categories: {},
    postsPerPage: 10,
    label: '篠栗',
    archivePath: '/shop/sasaguri/news/',
    description: Meta.siteName + " | 篠栗院からのお知らせ"    
  },
  "blog_sasaguri": {
    id: "blog_sasaguri",
    // id: "blog_hofu",
    name: "篠栗院ブログ",
    categories: {},
    postsPerPage: 12,
    label: '篠栗',
    archivePath: '/shop/sasaguri/blog/',
    description: Meta.siteName + " | 篠栗院のブログ"
  },
  "voice_sasaguri": {
    id: "voice_sasaguri",
    // id: "voice_hofu",
    name: "篠栗院のお客様の声",
    categories: {},
    postsPerPage: 12,
    label: '篠栗',
    archivePath: '/shop/sasaguri/voice/',
    description: Meta.siteName + " | 篠栗院のお客様の声"
  },

  // 直方院
  "news_noogata": {
    id: "news_noogata",
    name: "直方院からのお知らせ",
    categories: {},
    postsPerPage: 10,
    label: '直方',
    archivePath: '/shop/noogata/news/',
    description: Meta.siteName + " | 直方院からのお知らせ"    
  },
  "blog_noogata": {
    id: "blog_noogata",
    name: "直方院ブログ",
    categories: {},
    postsPerPage: 12,
    label: '直方',
    archivePath: '/shop/noogata/blog/',
    description: Meta.siteName + " | 直方院のブログ"
  },
  "voice_noogata": {
    id: "voice_noogata",
    name: "直方院のお客様の声",
    categories: {},
    postsPerPage: 12,
    label: '直方',
    archivePath: '/shop/noogata/voice/',
    description: Meta.siteName + " | 直方院のお客様の声"
  },
}

export const TopLatestNewsEndpoints = {
  "news_common": Endpoints.news_common,
  "news_sasaguri": Endpoints.news_sasaguri,
  "news_noogata": Endpoints.news_noogata,
  "news_hofu": Endpoints.news_hofu,
  "news_shimonoseki": Endpoints.news_shimonoseki,
  "news_nobeoka": Endpoints.news_nobeoka
}